<template>
    <div class="introducao">
        <p>  Bem-vindo à página dedicada do meu mais recente projeto de desenvolvimento web: "Previsão do Tempo em Tempo Real".
            Nesta plataforma, estou entusiasmado em apresentar a você não apenas as informações climáticas precisas
            e atualizadas, mas também demonstrar minhas habilidades no mundo do desenvolvimento web.</p>
        <p>Este projeto nasceu da minha paixão pela programação.O principal objetivo por trás da criação desta plataforma é não
            apenas fornecer uma visão instantânea e confiável do tempo, mas também exibir de maneira tangível minhas competências
            na área de desenvolvimento web.</p>
            <p>À medida que navegamos por esta plataforma interativa, você encontrará uma interface intuitiva e de fácil utilização.</p>
            <p> Acredito que esta plataforma não apenas reflete meu comprometimento, mas também serve como prova tangível do meu
                crescimento contínuo nesse setor dinâmico.</p>
            <p> Obrigado por dedicar seu tempo para conhecer meu trabalho, e espero que você saia daqui inspirado e informado.</p>
            <p>Sinta-se à vontade para entrar em contato comigo para qualquer dúvida, feedback ou oportunidades de colaboração.
                Juntos, podemos transformar ideias em realidade digital.</p>
    </div>
</template>
<script>
export default{
    name: 'IntroducaoSobre'
}
</script>
<style scoped>
.introducao{
    color: #a88cff;
    font-size: 16px;
    text-align: justify;
    font-weight: 400;
    text-indent: 20px;
    line-height: 1.5;
}
@media screen and (max-width: 768px) {
    .introducao{
        padding-top: 20px;
    }
}
</style>